import React, {ReactNode, useEffect, useState} from 'react'
import ReactDOM from 'react-dom';
import { clearSelected } from '../../../features/modals';
import {useAppDispatch} from '../../../hooks/hooks';
import MediaQuery  from 'react-responsive';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
    onConfirm: () => void;
    successTitle?: string;
    cancelTitle?: string;
    valid?: boolean;
}

export default function BaseModal(props: Props) {
    const {open, title, onClose, onConfirm, successTitle, cancelTitle, valid} = props;
    const dispatch = useAppDispatch();
    const [domReady, setDomReady] = useState(false)

    const close = () => {
        dispatch(clearSelected())
        onClose();
    }

    const confirm = () => {
        dispatch(clearSelected())
        onConfirm();
    }

    useEffect(() => {
        setDomReady(true)
    }, [])

    return domReady ? ReactDOM.createPortal(<dialog open={open}>
        <article>
            <MediaQuery minWidth={1000}>
                <header>
                    <a href="#" aria-label="Close" className="close" onClick={close}></a>
                    {title}
                </header>
            </MediaQuery>
            <div>
                <MediaQuery maxWidth={1000}>
                    <h2><ArrowBackIosNewIcon onClick={close} sx={{fontSize: '1.3rem', marginTop: '-5px'}}/><span style={{marginLeft:'1rem'}}>{title}</span></h2>
                </MediaQuery>
                {props.children}
            </div>
            <MediaQuery maxWidth={1000}>
                <div>
                    <button className="secondary" onClick={close}>{cancelTitle || 'Cancel'}</button>
                    <button disabled={typeof valid !== 'undefined' ? valid ? false : true : false} onClick={confirm}>{successTitle || 'OK'}</button>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                <footer>
                    <a href="#" role="button" className="secondary" onClick={close}>{cancelTitle || 'Cancel'}</a>
                    <a href={typeof valid !== 'undefined' ? valid ? '#' : void(0) : '#'} role="button" onClick={confirm}>{successTitle || 'OK'}</a>
                </footer>
            </MediaQuery>
        </article>
    </dialog>, document.getElementById('modal-timinator')!) : null;
}
