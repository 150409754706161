import {AnyAction, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ModalState} from './types';

const initialState: ModalState = {
    selectedEdit: null,
    selectedDelete: null,
};

const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        selectIdOnEdit: (state, action: PayloadAction<object>) => {
            state.selectedEdit = action.payload;
        },
        selectIdOnDelete: (state, action: PayloadAction<object>) => {
            state.selectedDelete = action.payload;
        },
        clearSelected: (state) => {
            state.selectedEdit = null;
            state.selectedDelete = null;
        }
    },
});

export const {selectIdOnEdit, selectIdOnDelete, clearSelected} = modalSlice.actions;
export default modalSlice.reducer;
