import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import {Employee} from '../types';

const initialState: Employee = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    position: '',
    admin: false,
    isActive: false,
    canEditRoles: false,
    canEditProjects: false,
    canEditAdminLabel: false,
    roles: [],
    projects: [],
}

export const getEmployee = createAsyncThunk(
    'employee/get/single',
    async (id: number) => {
        const response = await HttpClient.employeeApi.getEmployee<Employee>(id);
        return response.data;
    }
)

const typeSplice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployee.fulfilled, (state, action) => {
            return action.payload;
        })
    }
})

export const { clear } = typeSplice.actions

export default typeSplice.reducer;