import React, {useEffect, useState} from 'react';
import { Box, Stack } from '@mui/material';
import { SVGS } from '../../../../assets/images';
import styles from './settings.early-access.module.scss';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {getEarlyAccessEnabled, updateEarlyAccessEnabled} from '../../../../features/settings/early-access';
import TSwitch from '../../../../components/shared/form-controls/switch';
import {getProfile} from '../../../../features/profile';
export default function EarlyAccess() {
    const dispatch = useAppDispatch();
    const earlyAccess = useAppSelector(store => store.earlyAccess);
    const [loading, setLoading] = useState<boolean>(true);

    const setEarlyAccessEnabled = (enabled: boolean) => {
        dispatch(updateEarlyAccessEnabled(enabled)).unwrap().then(() => {
            dispatch(getEarlyAccessEnabled());
        })
    }

    useEffect(() => {
        setLoading(true);
        dispatch(getEarlyAccessEnabled()).then(() => {
            dispatch(getProfile());
            setLoading(false);
        });
    }, [earlyAccess.enabled]);

    return <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginTop: '2rem' }}>
        {
            !loading && <Stack spacing={3.2}>
                <Stack className={styles.warningBanner} spacing={3.3} direction='row'>
                    <Box className={styles.warningBannerIcon}><SVGS.ErrorIcon/></Box>
                    <span>
                        You can get early access to new features and functions. But they may contain bugs or work incorrectly.
                        <p>Bug fixes are distributed to everyone equally, regardless of early access.</p>
                    </span>
                </Stack>
                <TSwitch label='Enable early access' name='earlyaccessenabled' checked={earlyAccess.enabled} onChange={e => setEarlyAccessEnabled(e)}/>
            </Stack>
        }
    </Box>
}