import React from 'react'
import styles from './zoho-status.module.scss'
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { useNavigate, } from 'react-router-dom';

const ZohoStatus = () => {
    const navigate = useNavigate()
    const handleButtonClick = () => {
        navigate('/settings')
    }

    return (<>
        <h1>
            Zoho Invoices
        </h1>
        {
            window.location.pathname.includes('success') && <>
                <p><FormattedMessage id='zoho_created_successfully' /></p>
            </>
        }
        {
            window.location.pathname.includes('error') && <>
                <p><FormattedMessage id='unknown_error' /></p>
                <p><FormattedMessage id='we_re_working_on_it' /></p>
            </>
        }
        {
            window.location.pathname.includes('not_found') && <>
                <p><FormattedMessage id='zoho_request_not_found' /></p>
                <p><FormattedMessage id='try_again' /></p>
            </>
        }
        <p className={styles.pressButton}><FormattedMessage id='press_da_button_kronk'></FormattedMessage></p>
        <Button className={styles.backButton} variant='createNew' sx={{ width: 'auto', alignSelf: 'center', marginTop: '1.5rem' }} onClick={handleButtonClick}>
            Back to settings
        </Button>
    </>)
}

export default ZohoStatus;