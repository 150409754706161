import React from 'react';
import {Box, Button} from '@mui/material';
import styles from './subscription-banner.module.scss';
import {SubscriptionType} from './types';

interface SubscriptionProps {
    dayLeft: number;
    type: SubscriptionType;
    onClick: () => Promise<void>;
}

const SubscriptionBanner: React.FC<SubscriptionProps> = (props) => {
    const {type, onClick} = props;
    return (
        <Box className={styles.banner}>
            {
                type === SubscriptionType.Expired
                    ? <h5>Subscription expired</h5>
                    : <>
                        <h5>{ type === SubscriptionType.Trial ? 'Trial' : '' } Subscription</h5>
                        <p>Expires in: {props.dayLeft} days</p>
                    </>
            }
            <Button variant='createNew' onClick={async () => onClick()}>
                { type === SubscriptionType.Trial ? 'Connect full' : 'Renew' }
            </Button>
        </Box>
    )
}

export default SubscriptionBanner