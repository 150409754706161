import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import DeleteModal from '../../../../components/shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../assets/images';
import { Role } from '../../../../features/roles/types';
import {deleteRole, getRoles } from '../../../../features/roles';

interface Props {
    role: Role;
}

export default function DeleteRoleModal(props: Props) {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteRole(props.role.id)).unwrap().then(() => {
            onToggle();
            dispatch(getRoles(0));
        })
    }

    return <>
        {open && <DeleteModal open={open} title='role' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete role'
                    subtext={`Are you sure that you want to delete "${props}" role?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.role.name}&quot; role?
            </MediaQuery>
        </DeleteModal>}
    </>
}
