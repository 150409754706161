import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {locationHistory} from '../../helpers/location-history'
import HttpClient from '../../api/api'

export interface IAuthState {
    token: string | null;
    status: 'idle' | 'loading' | 'failed';
    error: any;
}

const initialState: IAuthState = {
    token: localStorage.getItem('token'),
    error: null,
    status: 'idle'
}

export const login = createAsyncThunk(
    'auth',
    async (code: string) => {
        const response = await HttpClient.authApi.login(code)
        return response.data
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null
            localStorage.removeItem('token')
            locationHistory.navigate('/logout')
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.error = null
                state.status = 'loading'
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'idle'
                const user = action.payload

                localStorage.setItem('token', user)
                state.token = user

                const { from } = locationHistory.location.state || { from: { pathname: '/' } }
                locationHistory.navigate(from)
            })
            .addCase(login.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
                state.error = 'authentication_code_can_not_be_used'
            })
    },
})

export const { logout } = authSlice.actions
export default authSlice.reducer
