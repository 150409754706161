import { store } from '../../store/store';
import {connectSubscription} from './subscription';
import {showSnack} from '../snacks/snackService';

export const ConnectSubscription = (): Promise<boolean> => {
    return store.dispatch(connectSubscription()).unwrap().then((result) => {
        if (!result.success) {
            if (result.needAddPaymentMethod) {
                showSnack('Unable to connect subscription. No payment method added', 'error');
                return false;
            }

            if (result.error) {
                showSnack(`Unable to connect subscription. ${result.errorMessage}`);
            }

            showSnack('Unable to connect subscription. Contact us.', 'error');
            return false;
        }

        showSnack('Subscription connected.', 'info');
        return true;
    })
}
