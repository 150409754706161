import {Box, Stack} from '@mui/material';
import React, {useEffect, useState} from 'react'
import styles from './notifications.module.scss';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {getNotificationsEnabled, updateNotificationsEnabled} from '../../../../features/settings/notifications';
import {Dropdown} from '../../../../components/shared/dropdown';
import TSwitch from '../../../../components/shared/form-controls/switch';

const channels = [
    { id: 1, name: 'general' }
]

export default function Notifications() {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector(store => store.notifications);
    const [loading, setLoading] = useState<boolean>(true);

    const channelStrings = channels.map(val => ({ id: val.id, name: `# ${val.name}` }))

    useEffect(() => {
        setLoading(true);
        dispatch(getNotificationsEnabled()).then(() => {
            setLoading(false)
        })
    }, [notifications.enabled])

    const notificationsChanged = (enabled: boolean) => {
        dispatch(updateNotificationsEnabled(enabled)).unwrap().then(() => {
            dispatch(getNotificationsEnabled());
        })
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
        height: '100%',
        marginTop: '2rem'
    }}>
        {
            !loading && <Stack className={styles.wrapper}>
                <Dropdown required={true} name='channelnotify' label='Channel for notifications' value={channelStrings[0].id} items={channelStrings} />
                <TSwitch name='notifyenabled' checked={notifications.enabled} onChange={(e) => notificationsChanged(e)} label='Notifications at the end of the month' />
            </Stack>
        }
    </Box>
}