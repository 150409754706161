import {useEffect, useState} from 'react';
import TableData, { Column } from '../components/shared/table/types';
import {PopupMenuAction} from '../components/shared/popup-menu/types';

export const useTableData = (columns: Column[], actions: PopupMenuAction[], data: object[], totalRows: number, mapData: (item: any) => object, sort?: (a: any, b: any) => number) => {
    const [tableSettings, setTableSettings] = useState<TableData>({
        columns,
        data: null,
        actions,
        totalRows,
    })

    useEffect(() => {
        const compData: object[] = sort ? data?.map(mapData).sort(sort) : data?.map(mapData);
        setTableSettings((prevState: TableData) => {
            return {
                ...prevState,
                totalRows,
                data: compData,
            }
        })
    }, [data])

    return tableSettings;
}
