import React, {useEffect} from 'react'
import {useAppDispatch} from '../../../../../../hooks/hooks';
import useModal from '../../../../../../hooks/useModal';
import DeleteModal from '../../../../../../components/shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../../../assets/images';
import {getPaymentState, removePaymentMethod} from '../../../../../../features/payments';

const DeletePaymentMethodModal = () => {
    const dispatch = useAppDispatch();
    const { open, onToggle, openModal } = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(removePaymentMethod()).unwrap().then(() => {
            onToggle();
            dispatch(getPaymentState());
        });
    }

    return <>
        {open && <DeleteModal open={open} title='payment method' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete payment method'
                    subtext={'Are you sure that you want to delete payment method?'}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete payment method?
            </MediaQuery>
        </DeleteModal>}
    </>
}

export default React.memo(DeletePaymentMethodModal)