import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {filterProjectType, getProjectTypes, updateProjectType} from '../../../../../../features/types';
import {TiminatorType} from '../../../../../../features/types/types';
import useModal from '../../../../../../hooks/useModal';
import EditModal from '../../../../../../components/shared/modal/edit-modal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../../../components/shared/form-controls/form-checkbox';

interface Props {
    projectType: TiminatorType;
}

interface EditForm {
    name: string;
    isDefault: boolean;
    isActive: boolean;
}

const EditProjectTypesModal = (props: Props) => {
    const {projectType} = props;
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const id = projectType.id;

    const initialValues: EditForm = {
        name: projectType.name,
        isDefault: projectType.default,
        isActive: projectType.active,
    };

    useEffect(() => {
        openModal();
    }, [])

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }
        return await dispatch(filterProjectType(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name) || name === initialValues.name.toLowerCase();
        });
    }

    const save = async (values: EditForm) => {
        const type: TiminatorType = {
            id,
            name: values.name,
            active: values.isActive,
            default: values.isDefault,
        }

        return dispatch(updateProjectType(type)).unwrap().then(() => {
            onToggle();
            dispatch(getProjectTypes(0));
        })
    }

    const validationScheme: Yup.Schema<EditForm> = Yup.object({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required('Required')
            .test(
                'type-name-backend-validation',
                'project type name is taken',
                async (name) => { return await validateName(name)}),
        isDefault: Yup.boolean().required(),
        isActive: Yup.boolean().required(),
    })

    return <span>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={save}>
            <Form>
                <EditModal open={open} title='project type' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Project type' />
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </EditModal>
            </Form>
        </Formik>}
    </span>
}

export default React.memo(EditProjectTypesModal);
