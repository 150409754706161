export default {
    logging_in: 'Logging In',
    authentication_code_can_not_be_used: 'Authentication code has expired or has already been used for authentication. Please request a new code for login.',
    authentication_is_not_done: 'Authentication is not complete. Please use the provided link from the configuration menu requested from the used messenger.',
    logged_out: 'Logged Out',
    log_out: 'Log out',
    types: 'Types',
    project_types: 'Project Types',
    shift_types: 'Shift Types',
    absences_types: 'Absences Types',
    roles: 'Roles',
    employees: 'Employees',
    clients: 'Clients',
    projects: 'Projects',
    settings: 'Settings',
    preferences: 'Preferences',
    notifications: 'Notifications',
    integrations: 'Integrations',
    earlyAccess: 'Early Access',
    workCalendar: 'Work Сalendar',
    billing: 'Billing',
    payments: 'Payments',
    currentPaymentMethod: 'Current Payment Method',
    paymentMethod_setup: 'Stripe is set',
    you_have_no_payment_method_added: 'You have not added a payment method',
    you_have_no_payment_method_added_desc: 'Add your debit or credit card to activate your subscription',
    add_payment_method: 'Add Payment Method',
    subscription: 'Subscription',
    timinator_trial: 'Trial Subscription',
    timinator_connected: 'Timinator Connected',
    your_sub_active_unt: 'Your subscription is active until {s}',
    per_human_per_month: 'For just {s} per human per month',
    try_timinator: 'Or try Timinator for free for 30 days',
    next_payment: 'Next Payment',
    get_list_of_features: 'Get the full list of features on',
    timinator_page: 'Timinator Page',
    profile: 'Profile',
    email: 'Email',
    create_new: 'Create new',
    delete: 'Delete',
    me: 'Me',
    required: 'Required',
    max_length_error: 'Must be 45 characters or less',
    phone_number_is_not_valid: 'Phone number is not valid',
    standard_work_day: 'standard work day',
    weekend_or_day_off: 'weekend or day off',
    customized: 'customized',
    empty_field_ability: 'You could use empty value for use global setting from workspace.',
    work_hours: 'Work hours',
    day_type: 'Day type',
    date_settings: 'Date settings: {s}',
    calendar: 'Calendar',
    year: 'Year',
    work_calendar: 'work calendar',
    calendarName: 'Calendar name',
    region: 'Region',
    workHours_in_day: 'Work hours in a day',
    workHours_in_shortDay: 'Work hours in a short day',
    edit_work_calendar_info: 'If change this value then all custom dates will be reseted for it calendar.',
    delete_work_calendar: 'Delete work calendar',
    delete_work_calendar_info: 'Are you sure that you want to delete "{s}" Work calendar',
    integration_connect: 'Connect',
    integration_disconnect: 'Disconnect',
    integration_configure: 'Configure',
    integration_disconnect_title: 'Disconnect {s}',
    integration_disconnect_info: 'Disconnect integration',
    integration_disconnect_desc: 'Are you sure that you want to disconnect "{s}" integration?',
    integration_disconnect_done: 'Disconnected: {s} integration',
    integration_name: 'Integration {s}',
    zoho_integration_link: 'https://www.zoho.com',
    zoho_integration_desc: 'Read more about service',
    zoho_organizations: 'Organizations',
    zoho_employees_for_notify: 'Employees for notifications',
    select_one_more_projects: 'Select one or more projects',
    select_one_more_employees: 'Select one or more employees',
    integration_wating_for_configuration: 'Waiting for configuration',
    unknown_error: 'Unknown error.',
    we_re_working_on_it: 'We are already aware and working on it.',
    zoho_created_successfully: 'Your zoho account was added successfully.',
    zoho_request_not_found: 'We have not found the number of your request for add a zoho integration.',
    try_again: 'Try again or contact us',
    press_da_button_kronk: 'You could press the button below to back to the settings.'
}
