import React, {CSSProperties, useState} from 'react';
import {useField, useFormikContext} from 'formik';
import styles from './form-dropdown.module.scss';
import {PIdNameModel} from '../../../../features/projects/types';

interface Props {
    label: string;
    name: string;
    initialValue?: PIdNameModel | {id:number | string, name: string};
    items: PIdNameModel[] | {id:number | string, name: string}[];
    onBlur?: (value: string) => void;
    placeholder?: string;
    required?: boolean;
    id?: string;
    style?: CSSProperties;
}

export const FormDropdown = (props: Props) => {
    const {setFieldValue, values} = useFormikContext();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const init = values[props.name]?.id as number;
    const [value, setValue] = useState<string | number | readonly string[] | undefined>(props.initialValue?.id?? init);
    const handleChange = (value: string | number | readonly string[] | undefined) => {
        setValue(value);
        const fullObject = props.items.find(item => item.id == value)
        setFieldValue(props.name, fullObject, props.required ?? false);
    }

    return (
        <div className={styles.wrapper}>
            <label htmlFor={props.id || props.name}>{props.label} {!props.required ? (<span className={styles.optional}>(optional)</span>) : '' }</label>
            <select style={props.style} required={props.required} onBlur={(e) => props.onBlur?.(e.target.value)} name={props.name} value={value} onChange={(e) => handleChange(e.target.value)}>
                {!props.required && <option value="">{props.placeholder}</option>}
                {
                    props.items.map(option => <option key={option.id} value={option.id}>{option.name}</option>)
                }
            </select>
        </div>
    );
};