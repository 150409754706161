import React, { ReactNode } from 'react'
import BaseModal from './base-modal';
import useSnack from '../../../hooks/useSnack';
import {useFormikContext} from 'formik';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
    onConfirm?: (values?: any) => void;
    valid?: boolean;
}

export default function CreateModal(props: Props) {
    const {open, title, onClose, onConfirm, valid} = props;
    const snack = useSnack();
    const { submitForm, isValid, validateForm } = useFormikContext();

    const onCreate = async () => {
        try {
            const hasErrors = Object.keys(await validateForm()).length !== 0;
            if (!hasErrors) {
                await submitForm();
                snack(`Created: ${title}`, 'success');
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    return <BaseModal open={open} title={`Create ${title}`} onClose={onClose} onConfirm={onCreate} successTitle='Create' valid={isValid}>
        {props.children}
    </BaseModal>
}
