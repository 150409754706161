import {AxiosInstance, AxiosResponse} from 'axios';

export interface IPermissionApi {
    getAll<T>(): Promise<AxiosResponse<T>>,
}

export default class PermissionApi implements IPermissionApi {
    private readonly _axiosInstance;
    private readonly controller = 'permissions';
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }
    async getAll<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/all`);
    }
}
