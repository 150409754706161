import React, {useEffect, useState} from 'react'
import {Column} from '../../components/shared/table/types';
import {PopupMenuAction} from '../../components/shared/popup-menu/types';
import Status from '../../components/shared/status';
import {SVGS} from '../../assets/images';
import {Employee} from '../../features/employees/types';
import {store} from '../../store/store';
import {selectIdOnDelete, selectIdOnEdit} from '../../features/modals';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {useTableData} from '../../hooks/useTableData';
import PageWrapper from '../../components/shared/page-wrapper';
import {Box} from '@mui/material';
import TiminatorTable from '../../components/shared/table';
import IconTextLarge from '../../components/shared/large-icon-text';
import {Client} from '../../features/clients/types';
import CreateClientModal from './components/create-modal';
import EditClientModal from './components/edit-modal';
import DeleteClientModal from './components/delete-modal';
import {getClients} from '../../features/clients';
import {FormattedMessage} from 'react-intl';


const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        format: (rowData: Client) => {
            return <span>
                <div>{rowData.name}</div>
                {rowData.contactPerson && <div style={{marginTop: '0.8rem', fontSize: '0.75rem', fontWeight: '400'}}>
                    👨‍💼<span style={{marginLeft: '0.3rem'}}>{rowData.contactPerson}</span>
                    {rowData.email && <span style={{marginLeft: '0.3rem', color: '#F26D6B'}}>{rowData.email}</span>}
                </div>}
            </span>
        }
    },
    {
        id: 'status',
        label: 'Status',
        isStatus: true,
        align: 'left',
        format: (rowData: Client) => <Status color={rowData.isActive ? '#59CA59' : '#F26D6B'} value={rowData.isActive ? 'Active' : 'Suspended'} />
    },
];

const actions: PopupMenuAction[] = [
    {
        name: 'Edit',
        icon: <SVGS.EditIcon />,
        callback: function(rowData: Employee) {
            store.dispatch(selectIdOnEdit(rowData))
        },
    },
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon />,
        callback: function(rowData: Employee) {
            store.dispatch(selectIdOnDelete(rowData))
        },
    }
]

const mapData = (item: Client): Client => item;

const sort = (a: Client, b: Client) => {
    if (a.isActive !== b.isActive) {
        return a.isActive ? -1 : 1;
    } else {
        return a.name > b.name ? 1 : -1;
    }
}

export default function ClientPage() {
    const dispatch = useAppDispatch();
    const clients = useAppSelector(store => store.clients)
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit)
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete)
    const tableSettings = useTableData(columns, actions, clients.items, clients.total, mapData, sort);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const fetchData = async (page: number) => {
        dispatch(getClients(page))
    }

    useEffect(() => {
        setIsEmpty(clients.total === 0);
    }, [clients.total])

    return  <PageWrapper>
        <h3><FormattedMessage id={'clients'} /></h3>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', overflowY: 'auto'}}>
            {
                !isEmpty
                    ? <TiminatorTable tableSettings={tableSettings} fetchData={fetchData} offset={clients.offset}/>
                    : <IconTextLarge text='You have no clients' subtext='Create client and add detailed info about it' icon={<SVGS.ClientsIcon />} />
            }
            <CreateClientModal />
            {selectedEdit && <EditClientModal client={selectedEdit as Client}/>}
            {selectedDelete && <DeleteClientModal client={selectedDelete as Client}/>}
        </Box>
    </PageWrapper>
};
