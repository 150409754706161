import React, {useState} from 'react'
import {Box, Tab, Tabs} from '@mui/material';
import PageWrapper from '../../components/shared/page-wrapper';
import TabPanel from '../../components/shared/tab-panel';
import Subscription from './components/subscrption';
import Payments from './components/payments';
import {isVisible} from '../../helpers/profileHelper';
import {Part} from '../../features/profile/types';
import {useAppSelector} from '../../hooks/hooks';
import {FormattedMessage} from 'react-intl';

export default function Billing() {
    const [value, setValue] = useState(0);
    const profile = useAppSelector(store => store.profile);

    function handleChange(event: React.SyntheticEvent, newValue: number) {
        setValue(newValue);
    }

    return <PageWrapper>
        <h3><FormattedMessage id={'billing'} /></h3>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} scrollButtons='auto' variant='scrollable'>
                { isVisible(profile, Part.Payments) && <Tab disableRipple={true} label={<FormattedMessage id={'payments'} />} /> }
                <Tab disableRipple={true} label={<FormattedMessage id={'subscription'} />} />
            </Tabs>
        </Box>
        <TabPanel index={value} value={0}>
            <Payments />
        </TabPanel>
        <TabPanel index={value} value={1}>
            <Subscription />
        </TabPanel>
    </PageWrapper>
}