import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import {PIdNameModel} from '../types';


const initialState: PIdNameModel[] = []

export const getProjects = createAsyncThunk(
    'projects/all',
    async () => {
        const response = await HttpClient.projectApi.getAllProjects<PIdNameModel[]>();
        return response.data
    }
)

export  const filterProjects = createAsyncThunk(
    'projects/filter',
    async (filter: string) => {
        const response = await HttpClient.projectApi.filterProjects<PIdNameModel[]>(filter);
        return response.data;
    }
)

const typeSplice = createSlice({
    name: 'Eprojects',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjects.fulfilled, (state, action) => {
            return [...action.payload];
        })
    }
})

export const { clear } = typeSplice.actions

export default typeSplice.reducer;
