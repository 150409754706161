import React from 'react'
import moment from 'moment'
import {Box, Button, Stack} from '@mui/material';
import styles from './subscription-banner.module.scss';
import {SVGS} from '../../../../../../assets/images';
import {FormattedMessage} from 'react-intl';

interface Props {
    active: boolean,
    label: string,
    nextDate: string,
    perHumanPrice: string,
    onClick: () => Promise<void>,
}

const SubscriptionBanner = (props: Props) => {
    const {onClick} = props;
    const nextPaymentDate = moment(props.nextDate).format('Do MMMM YYYY');
    const connected = (untilDate: string) => {
        return <>
            <h4>{props.label}</h4>
            <p><FormattedMessage id={'your_sub_active_unt'} values={{ s: untilDate }} /></p>
        </>
    }

    const disconnected = () => {
        return <>
            <h4><FormattedMessage id={'per_human_per_month'} values={{ s: props.perHumanPrice }} /></h4>
            <p><FormattedMessage id={'try_timinator'} /></p>
        </>
    }


    return (
        <Box className={styles.banner}>
            <Box className={styles.content}>
                <SVGS.LogoSkeletonIcon />
                <Stack direction='column' spacing={1}>
                    {
                        props.active
                            ? connected(nextPaymentDate)
                            : disconnected()
                    }
                </Stack>
            </Box>
            {
                props.active
                    ? <Box className={styles.nextPayment}>
                        <span><FormattedMessage id={'next_payment'} />:</span>
                        <p>{ nextPaymentDate }</p>
                    </Box>
                    : <Button variant='createNew' onClick={async () => await onClick()}>
                        <SVGS.PlusIcon /><span style={{marginLeft: 13}}>Connect</span>
                    </Button>
            }
        </Box>
    )
}

export default React.memo(SubscriptionBanner)