import React, {useEffect, useState} from 'react'
import {Box, Grid, Stack} from '@mui/material';
import {Day, IdNameModel, Month, WorkCalendar, isCalendar, isDay, isNotEmptyCalendar} from '../../../../../../features/settings/workcalendar/types';
import moment from 'moment';
import MonthView from '../month';
import {
    getMonth,
    getRegions,
    getWorkCalendar,
    getWorkCalendars,
    setCurrentYear,
    setWorkCalendarId,
} from '../../../../../../features/settings/workcalendar';
import {store} from '../../../../../../store/store';
import {selectIdOnDelete, selectIdOnEdit} from '../../../../../../features/modals';
import {useAppDispatch, useAppSelector} from '../../../../../../hooks/hooks';
import EditMonthDayModal from '../day/edit-modal';
import DaysInfo from '../day/info';
import {Dropdown} from '../../../../../../components/shared/dropdown';
import styles from './normal-calendar.module.scss';
import {styled} from '@mui/material/styles';
import theme from '../../../../../../themes/main/theme';
import {useIntl} from 'react-intl';
import PopupMenuActions from '../../../../../../components/shared/popup-menu';
import {SVGS} from '../../../../../../assets/images';
import { PopupMenuAction } from '../../../../../../components/shared/popup-menu/types';
import EditCalendarModal from '../calendar/edit-modal';
import CreateCalendarModal from '../calendar/create-modal';
import DeleteCalendarModal from '../calendar/delete-modal';
import { MonthDay, isMonthDay } from '../../types';

const MonthGrid = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0, min(100%/1, max(464px, 100%/5))))',
    rowGap: '32px',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
        justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'space-arround',
    }
})

const NormalCalendar = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch();
    const { workCalendars, workCalendarId, year, regions, currentYear, availableYears } = useAppSelector(store => store.workCalendar);
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit);
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete);
    const [loading, setLoading] = useState<boolean>(true);

    const availableWorkCalendars = workCalendars.map(x=>({id:x.id, name:x.name}));

    const handleWorkCalendarSelect = (value: string) => {
        dispatch(setWorkCalendarId(parseInt(value)));
    }

    const handleWorkCalendarYearSelected = async (value: string) => {
        dispatch(setCurrentYear(parseInt(value)))
    }

    const handleDayClick = (month: number, day: Day) => {
        dispatch(getMonth({ workCalendarId: workCalendarId, year: currentYear, month: month })).unwrap().then(() => {
            store.dispatch(selectIdOnEdit({ month: month, day: day } as MonthDay));
        })
    }

    const handleDayUpdateFinished = async () => {
        setLoading(true);
        dispatch(getWorkCalendar({workCalendarId: workCalendarId, year: currentYear}))
            .unwrap()
            .then(() => setLoading(false));
    }

    const handleCaledarEdit = () => {
        const workCalendar = workCalendars.filter(w => w.id == workCalendarId)[0]
        store.dispatch(selectIdOnEdit(workCalendar))
    }

    const handleCalendarCreate = () => {
        const emptyCalendar: WorkCalendar = {
            id: 0,
            name: '',
            region: 0,
            workHours: 0,
            shortWorkHours: 0
        }
        store.dispatch(selectIdOnEdit(emptyCalendar))
    }

    const handleCalendarCreated = async () => {
        // return await fetchWorkCalendar()
    }

    const handleCalendarDelete = () => {
        const workCalendar = workCalendars.find(w => w.id == workCalendarId)!
        store.dispatch(selectIdOnDelete(workCalendar))
    }

    const handleCalendarDeleted = async () => {
        return await dispatch(getWorkCalendars())
    }

    const calendarActions = (calendarId: number): PopupMenuAction[] => {
        const defaultFunctions = [
            {
                name: 'Create',
                icon: <SVGS.PlusIcon style={{ fill: '#565555' }} />,
                callback: function () { handleCalendarCreate() }
            },
            {
                name: 'Edit',
                icon: <SVGS.EditIcon />,
                callback: function () { handleCaledarEdit() }
            }
        ]

        if (calendarId > 1) {
            defaultFunctions.push({
                name: 'Delete',
                icon: <SVGS.DeleteIcon />,
                callback: function () { handleCalendarDelete() }
            })
        }

        return defaultFunctions
    }

    const fetchData = async (workCalendarId: number, year: number) => {
        setLoading(true);
        dispatch(getWorkCalendar({workCalendarId: workCalendarId, year: year}))
            .unwrap()
            .then(() => setLoading(false));
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getRegions())
        dispatch(getWorkCalendars()).unwrap().then(() => {
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if(workCalendarId < 1) return;
        fetchData(workCalendarId, currentYear)
    }, [workCalendarId, currentYear])

    const getMonthOfCalendar = (year: number, month: Month) => {
        const dateObject = moment(new Date(`${year}/${month.month}/01`));

        return <Box className={styles.monthWrapper}>
            <Stack direction='column'>
                <Box className={styles.name}>{ dateObject.format('MMMM') }</Box>
                <MonthView month={month} onDayClick={(d) => handleDayClick(month.month, month.days.find(x => x.day === d)!)} dateObject={dateObject} />
            </Stack>
        </Box>
    }

    return <>
        {
            !loading && <Stack direction='column' spacing={5} sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', height: 'calc(100% - 1.5rem)', alignItems: 'flex-start' }}>
                <Stack alignItems='start' direction='column' spacing={2}>
                    <Box display='grid' gridAutoFlow='column'>
                        <Box display='grid' gridAutoFlow='row' marginRight={'0.5rem'} alignItems={'center'} minWidth={'8rem'}>
                            {
                                workCalendars && <Dropdown required={true} label={intl.formatMessage({ id: 'calendar' })} name='name' items={availableWorkCalendars} value={workCalendarId} onChange={handleWorkCalendarSelect} key={workCalendarId} />
                            }
                        </Box>
                        <Box display='grid' gridAutoFlow='row' marginRight={'0.5rem'} alignItems={'center'} minWidth={'8rem'}>
                            <Dropdown required={true} label={intl.formatMessage({ id: 'year' })} name='year' items={availableYears} value={currentYear} onChange={handleWorkCalendarYearSelected} key={currentYear} />
                        </Box>
                        <Box display='grid' gridAutoFlow={'row'} alignItems={'center'} marginTop={'0.8rem'}>
                            <PopupMenuActions actionIcon={<SVGS.ActionVertIcon style={{ cursor: 'pointer' }} />} actions={calendarActions(workCalendarId)} rowItem={null} />
                        </Box>
                    </Box>
                    <DaysInfo />
                </Stack>
                <Box sx={{ height: 'calc(100% - 1.5rem)', width: '100%' }}>
                    {
                        year?.months && !loading && <MonthGrid className={styles.calendar}>
                            {
                                year.months.map((month, i) => (
                                    <Grid item key={i} width='464px'>
                                        { getMonthOfCalendar(year.year, month) }
                                    </Grid>
                                ))
                            }
                        </MonthGrid>
                    }
                </Box>
            </Stack>
        }
        {selectedEdit && isCalendar(selectedEdit as WorkCalendar) && <CreateCalendarModal regions={regions} onCreate={handleCalendarCreated} />}
        {selectedEdit && isNotEmptyCalendar(selectedEdit as WorkCalendar) && <EditCalendarModal regions={regions} calendar={selectedEdit as WorkCalendar} />}
        {selectedEdit && isMonthDay(selectedEdit as MonthDay) && <EditMonthDayModal day={(selectedEdit as MonthDay).day} year={currentYear} month={(selectedEdit as MonthDay).month} workCalendarId={workCalendarId} onUpdate={handleDayUpdateFinished} />}
        {selectedDelete && <DeleteCalendarModal workCalendar={selectedDelete as WorkCalendar} onDelete={handleCalendarDeleted} />}
    </>
}

export default React.memo(NormalCalendar)