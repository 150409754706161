import React, { ReactElement } from 'react'
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom'
import ROUTES from './routes'
import NotFoundPage from '../pages/not-found-page'
import MainLayout from '../components/main-layout'
import LoginPage from '../pages/LoginPage'
import {locationHistory} from '../helpers/location-history'
import TypesPage from '../pages/Types';
import RolesPage from '../pages/Roles';
import EmployeesPage from '../pages/Empoyees';
import ClientsPage from '../pages/Clients';
import ProjectsPage from '../pages/Projects';
import SettingsPage from '../pages/Settings';
import ProfilePage from '../pages/Profile';
import BillingPage from '../pages/Billing';
import moment from 'moment';
import LogoutPage from '../pages/LogoutPage';
import ZohoStatus from '../pages/Integrations/zoho-status'
import DialogLayout from '../components/dialog-layout'

const AppRouter = (): ReactElement => {
    locationHistory.navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locationHistory.location = useLocation()
    moment.updateLocale('en', {
        week: {
            dow: 1,
            doy: 7
        }
    });
    moment.locale('en');
    return  <Routes>
        <Route element={<MainLayout />}>
            <Route path={ROUTES.types.url} element={<TypesPage />} />
            <Route path={ROUTES.roles.url} element={<RolesPage />} />
            <Route path={ROUTES.employees.url} element={<EmployeesPage />} />
            <Route path={ROUTES.clients.url} element={<ClientsPage />} />
            <Route path={ROUTES.projects.url} element={<ProjectsPage />} />
            <Route path={ROUTES.settings.url} element={<SettingsPage />} />
            <Route path={ROUTES.billing.url} element={<BillingPage />} />
            <Route path={ROUTES.profile.url} element={<ProfilePage />} />
        </Route>
        <Route path={ROUTES.logout.url} element={<LogoutPage />} />
        <Route element={<DialogLayout />}>
            <Route path={ROUTES.login} element={<LoginPage />} />
            <Route path={`${ROUTES.zoho}/*`} element={<ZohoStatus />} />
            <Route path="*" element={<NotFoundPage />} />
        </Route>
    </Routes>
}

export default AppRouter
