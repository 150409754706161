import React, {useState} from 'react'
import ROUTES from '../../routes/routes';
import MenuItem from './blocks/menu-item';
import styles from './menu.module.scss';
import {SVGS} from '../../assets/images';
import {Box} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {isVisible} from '../../helpers/profileHelper';
import {Part} from '../../features/profile/types';
import SubscriptionBanner from './blocks/subscription-banner';
import moment from 'moment';
import {SubscriptionType} from './blocks/subscription-banner/types';
import MediaQuery from 'react-responsive';
import {ConnectSubscription} from '../../features/billing/subscriptionService';
import {doPayment} from '../../features/payments';
import {showSnack} from '../../features/snacks/snackService';

interface SubscriptionState {
    enabled: boolean,
    type: SubscriptionType,
    daysLeft: number
}

export default function Menu() {
    const dispatch = useAppDispatch();
    const profile = useAppSelector(store => store.profile);
    const {subscription} = useAppSelector(store => store.subscription);
    const [open, setOpen] = useState<boolean>(false);

    const toggle = () => {
        setOpen(prev => !prev);
    }

    const handleSubscriptionClick = async () => {
        const result = await ConnectSubscription();
        if (result) {
            await dispatch(doPayment(window.location.origin)).unwrap().then((result) => {
                if (result.success){
                    return;
                }

                if (result.needThreeDSecure) {
                    showSnack('Redirecting to 3D secure page in 3 seconds...',);
                    const timeout = setTimeout(() => {
                        window.location.replace(result.threeDSecureUrl);
                    }, 3000);
                    return () => clearTimeout(timeout);
                }

                if (result.error) {
                    showSnack(`Unable to do payment. ${result.errorMessage}`, 'error');
                }
            })
        }
    }

    const calculateExpirationDays = (): number => {
        const currentDate = moment();
        const nextDate = moment(subscription.nextPaymentDate);

        return nextDate.diff(currentDate, 'days');
    }

    const getSubscriptionState = (daysLeft: number): SubscriptionState => {
        if (!subscription.subscriptionEnabled) {
            return {
                enabled: false,
                type: SubscriptionType.Expired,
                daysLeft: daysLeft
            };
        }


        if (subscription.freeDaysCount > 0) {
            return {
                type: SubscriptionType.Trial,
                daysLeft: daysLeft,
                enabled: true
            };
        }

        if (daysLeft > 0){
            return {
                type: SubscriptionType.Available,
                daysLeft: daysLeft,
                enabled: true
            };
        }

        return {
            enabled: true,
            type: SubscriptionType.Expired,
            daysLeft: 0
        };
    }

    const subscriptionBanner = () => {
        const daysLeft = calculateExpirationDays();
        const state = getSubscriptionState(daysLeft);

        const banner = (<SubscriptionBanner dayLeft={daysLeft} type={state.type} onClick={handleSubscriptionClick} />)
        return (subscription.subscriptionEnabled && ((state.type === SubscriptionType.Trial || daysLeft <= 7) || state.type === SubscriptionType.Expired) && <>
            <MediaQuery minWidth={1000}>
                <Box className={styles.subscriptionBanner}>
                    {banner}
                </Box>
            </MediaQuery>
            <MediaQuery maxWidth={1000}>
                <Box className={styles.subscriptionBanner + ' ' + styles.openedSubscriptionWrapper}>
                    {banner}
                </Box>
            </MediaQuery>
        </>)
    }

    return (<>
        <div className={styles.desktopMenu}>
            <div>
            	<Box my={2}>
                	<SVGS.LogoIcon />
            	</Box>
            	<div className={styles.menuItems}>
                	{(isVisible(profile, Part.AbsenceTypes)
                            || isVisible(profile, Part.ShiftTypes)
                            || isVisible(profile, Part.ProjectTypes))
                        && <MenuItem route={ROUTES.types} />}
                	{isVisible(profile, Part.Roles) && <MenuItem route={ROUTES.roles} />}
                	{isVisible(profile, Part.Employees) && <MenuItem route={ROUTES.employees} />}
                	{isVisible(profile, Part.Clients) && <MenuItem route={ROUTES.clients} />}
                	{isVisible(profile, Part.Projects) && <MenuItem route={ROUTES.projects} />}
                    {(isVisible(profile, Part.StartAndEndDayTime)
                            || isVisible(profile, Part.NotificationAtEndOfMonth)
                            || isVisible(profile, Part.EarlyAccess)
                            || isVisible(profile, Part.Payments)
                            || isVisible(profile, Part.WorkCalendar))
                        && <MenuItem route={ROUTES.settings} />}
                    {isVisible(profile, Part.Subscription) && <MenuItem route={ROUTES.billing}/>}
                	<MenuItem route={ROUTES.profile} />
                </div>
            </div>
            { isVisible(profile, Part.Subscription) && subscription && subscriptionBanner() }
        </div>
        <div className={styles.mobileMenu}>
            <Box>
                <SVGS.LogoMobileIcon />
            </Box>
            <Box>
                {!open ? <SVGS.MenuMobileIcon onClick={toggle}/> : <CloseIcon onClick={toggle}/>}
            </Box>
        </div>
        {open && <Box className={styles.opened}>
            <div className={styles.openedMenuWrapper}>
                {(isVisible(profile, Part.AbsenceTypes)
                        || isVisible(profile, Part.ShiftTypes)
                        || isVisible(profile, Part.ProjectTypes))
                    && <MenuItem route={ROUTES.types} callback={toggle} />}
                {isVisible(profile, Part.Roles) &&
                    <MenuItem route={ROUTES.roles} callback={toggle} />}
                {isVisible(profile, Part.Employees)
                    && <MenuItem route={ROUTES.employees} callback={toggle} />}
                {isVisible(profile, Part.Clients)
                    && <MenuItem route={ROUTES.clients} callback={toggle} />}
                {isVisible(profile, Part.Projects)
                    && <MenuItem route={ROUTES.projects} callback={toggle} />}
                {(isVisible(profile, Part.StartAndEndDayTime)
                        || isVisible(profile, Part.NotificationAtEndOfMonth)
                        || isVisible(profile, Part.EarlyAccess)
                        || isVisible(profile, Part.Payments)
                        || isVisible(profile, Part.WorkCalendar))
                    && <MenuItem route={ROUTES.settings} callback={toggle} />}
                {isVisible(profile, Part.Subscription)
                    && <MenuItem route={ROUTES.billing} callback={toggle} />}
                <MenuItem route={ROUTES.profile} callback={toggle} />
            </div>
            { isVisible(profile, Part.Subscription) && subscription && subscriptionBanner() }
        </Box>}
    </>)
}
