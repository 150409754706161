import React from 'react';

export default function useModal() {
    const [open, setOpen] = React.useState(false);

    const onToggle = () => {
        setOpen((prev) => !prev);
    };

    const openModal = () => {
        setOpen(true);
    }

    return {
        open,
        onToggle,
        openModal,
    };
}
