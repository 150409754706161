import React from 'react';
import {Button} from '@mui/material';
import { SVGS } from '../../../assets/images';
import {FormattedMessage} from 'react-intl';

interface Props {
    onClick: () => void,
}

export default function CreateNewButton(props: Props) {
    return <Button variant='createNew' onClick={props.onClick}>
        <SVGS.PlusIcon style={{ fill: '#FFF' }} /><span style={{marginLeft: 10}}><FormattedMessage id={'create_new'} /></span>
    </Button>
}
