import { Box, Stack } from '@mui/material'
import React from 'react'
import styles from './profile-section-banner.module.scss'

interface Props {
    name: string,
    content: string
}

export default function ProfileSectionBanner(props: Props) {
    const bannerClass = `${styles.banner}`;
    return (
        <Box className={bannerClass}>
            <Stack className={styles.contentWrapper}>
                <div>{props.name}</div>
                <span>{ props.content }</span>
            </Stack>
        </Box>
    )
}