import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive';
import useModal from '../../../../../../hooks/useModal';
import { useIntl } from 'react-intl';
import IconTextLarge from '../../../../../../components/shared/large-icon-text';
import { SVGS } from '../../../../../../assets/images';
import InfoModal from '../../../../../../components/shared/modal/info-modal';
import { Integration } from '../../../../../../features/settings/integrations/types';

interface Props {
    integration: Integration;
    onDisconnectClick: (integration: Integration) => Promise<void>;
}

const DisconnectIntegrationModal = (props: Props) => {
    const { integration } = props;
    const { open, onToggle, openModal } = useModal();
    const intl = useIntl();

    const complete = async () => {
        onToggle();
        await props.onDisconnectClick(props.integration);
    }

    useEffect(() => {
        openModal();
    }, []);

    return (<span>
        {
            open && <InfoModal
                open={open}
                title={intl.formatMessage({ id: 'integration_disconnect_title' },
                    { s: integration.name })}
                onClose={onToggle}
                confirmLabel={intl.formatMessage({ id: 'integration_disconnect' })}
                confirmDoneInfo={intl.formatMessage({id: 'integration_disconnect_done'}, {s: integration.name})}
                onConfirm={complete}>
                <MediaQuery maxWidth={1000}>
                    <IconTextLarge text={intl.formatMessage({ id: 'integration_disconnect_info' })}
                        subtext={intl.formatMessage({ id: 'integration_disconnect_desc' }, { s: integration.name })}
                        icon={<SVGS.CancelIcon />} />
                </MediaQuery>
                <MediaQuery minWidth={1000}>
                    {intl.formatMessage({ id: 'integration_disconnect_desc' }, { s: integration.name })}
                </MediaQuery>
            </InfoModal>
        }
    </span>
    )
}

export default React.memo(DisconnectIntegrationModal)