import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {
    deleteShiftType,
    getShiftsTypes
} from '../../../../../../features/types';
import useModal from '../../../../../../hooks/useModal';
import DeleteModal from '../../../../../../components/shared/modal/delete-modal';
import { TiminatorType } from '../../../../../../features/types/types';
import MediaQuery from 'react-responsive';
import {SVGS} from '../../../../../../assets/images';
import IconTextLarge from '../../../../../../components/shared/large-icon-text';

interface Props {
    shiftType: TiminatorType;
}

const DeleteShiftTypesModal = (props: Props) => {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteShiftType(props.shiftType.id)).unwrap().then(() => {
            onToggle();
            dispatch(getShiftsTypes(0));
        })
    }

    return <>
        {open && <DeleteModal open={open} title='shift type' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete shift type'
                    subtext={`Are you sure that you want to delete "${props.shiftType.name}" shift type?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.shiftType.name}&quot; shift type?
            </MediaQuery>
        </DeleteModal>}
    </>
}

export default React.memo(DeleteShiftTypesModal);
