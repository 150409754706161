import React, {useEffect} from 'react'
import {OptionsObject, SnackbarKey, useSnackbar} from 'notistack'
import {useAppDispatch, useAppSelector} from './hooks'
import {closeSnack, removeSnack} from '../features/snacks/snacksSlice'
import CloseIcon from '@mui/icons-material/Close'

let displayed: SnackbarKey[] = []

const useSnacks = () => {
    const dispatch = useAppDispatch()
    const snacks = useAppSelector(store => store.snacks.snacks || [])
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = [...displayed.filter((key: SnackbarKey) => id !== key)]
    }

    useEffect(() => {
        snacks.forEach(({ key, message, options, dismissed = false }: {key: SnackbarKey, message: string, options: OptionsObject, dismissed: boolean}) => {
            if (dismissed) {
                closeSnackbar(key)
                return
            }

            if (displayed.includes(key)) return
            const newOptions = {...options, action: (key: SnackbarKey) => (
                <CloseIcon sx={{cursor: 'pointer'}} onClick={() => dispatch(closeSnack(key))} />
            )}
            enqueueSnackbar(message, {
                key,
                ...(newOptions as OptionsObject),
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey)
                    }
                },
                onExited: (event, myKey) => {
                    dispatch(removeSnack(myKey))
                    removeDisplayed(myKey)
                },
            })

            storeDisplayed(key)
        })
    }, [snacks, closeSnackbar, enqueueSnackbar])
}

export default useSnacks
