import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import { Subscription, ConnectResult } from './types';

export interface SubscriptionState {
    subscription: Subscription,
    connectResult: ConnectResult
}

const initialState: SubscriptionState = {
    subscription: {
        currency: '',
        perHumanPrice: 0.0,
        totalPrice: 0.0,
        subscriptionEnabled: false,
        freeDaysCount: 0,
        nextPaymentDate: ''
    },
    connectResult: {
        error: false,
        errorMessage: '',
        success: false,
        needAddPaymentMethod: true
    }
}

export const getSubscription = createAsyncThunk(
    'subscription/get',
    async () => {
        const response = await HttpClient.subscriptionApi.get<Subscription>();
        return response.data;
    }
)

export const connectSubscription = createAsyncThunk(
    'subscription/connect',
    async () => {
        const response = await HttpClient.subscriptionApi.connect<ConnectResult>();
        return response.data;
    }
)

const typeSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        clearSubscription: (state) => {
            state.subscription = initialState.subscription;
        },
        clearConnectResult: (state) => {
            state.connectResult = initialState.connectResult;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSubscription.fulfilled, (state, action) => {
            state.subscription = action.payload;
        });
        builder.addCase(connectSubscription.fulfilled, (state, action) => {
            state.connectResult = action.payload;
        });
    }
});

export default typeSlice.reducer