import React, {ChangeEvent} from 'react'
import {v4 as uuidv4} from 'uuid';
import {useField} from 'formik';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    name: string;
}

export const FormCheckbox = (props: Props) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (<label htmlFor={props.name} className="checkbox-input">
        <input type="checkbox" role='switch' {...field} {...props} />
        {props.label}
    </label>
    );
};
