import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import DeleteModal from '../../../../components/shared/modal/delete-modal';
import MediaQuery from 'react-responsive';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {SVGS} from '../../../../assets/images';
import {Employee} from '../../../../features/employees/types';
import {deleteEmployee, getEmployees} from '../../../../features/employees';

interface Props {
    employee: Employee;
}

export default function DeleteEmployeeModal(props: Props) {
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();

    useEffect(() => {
        openModal();
    }, [])

    const save = () => {
        return dispatch(deleteEmployee(props.employee.id)).unwrap().then(() => {
            onToggle();
            dispatch(getEmployees(0));
        })
    }

    return <>
        {open && <DeleteModal open={open} title='employee' onClose={onToggle} onConfirm={save}>
            <MediaQuery maxWidth={1000}>
                <IconTextLarge text='Delete employee'
                    subtext={`Are you sure that you want to delete "${props.employee.firstName} ${props.employee.lastName}"?`}
                    icon={<SVGS.DeleteIcon />} />
            </MediaQuery>
            <MediaQuery minWidth={1000}>
                Are you sure that you want to delete &quot;{props.employee.firstName} {props.employee.lastName}&quot;?
            </MediaQuery>
        </DeleteModal>}
    </>
}
