import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {TiminatorAbsenceType, TiminatorAbsenceTypes, TiminatorType, TiminatorTypes} from './types';

export interface TypesState {
    projects: TiminatorTypes,
    shifts: TiminatorTypes,
    absences: TiminatorAbsenceTypes,
}

const initialState: TypesState = {
    projects: {
        items: [],
        offset: 0,
        total: 0,
        permissions: [],
    },
    shifts: {
        items: [],
        offset: 0,
        total: 0,
        permissions: [],
    },
    absences: {
        items: [],
        offset: 0,
        total: 0,
        permissions: [],
    }
}

export const getProjectTypes = createAsyncThunk(
    'project-types/get',
    async (page: number) => {
        const response = await HttpClient.typeApi.getTypes<TiminatorTypes>('projects', page);
        return response.data
    }
)

export const addProjectType = createAsyncThunk(
    'project-types/create',
    async (projectType: TiminatorType) => {
        const response = await HttpClient.typeApi.add<TiminatorType>('projects', projectType);
        return response.data
    }
)

export const updateProjectType = createAsyncThunk(
    'project-types/update',
    async (projectType: TiminatorType) => {
        const response = await HttpClient.typeApi.update<TiminatorType>('projects', projectType);
        return response.data
    }
)

export const deleteProjectType = createAsyncThunk(
    'project-types/delete',
    async (id: number) => {
        const response = await HttpClient.typeApi.delete<TiminatorType>('projects', id);
        return response.data
    }
)

export const getAbsencesTypes = createAsyncThunk(
    'absences-types/get',
    async (page: number) => {
        const response = await HttpClient.typeApi.getTypes<TiminatorAbsenceTypes>('absences', page);
        return response.data
    }
)

export const addAbsenceType = createAsyncThunk(
    'absences-types/create',
    async (type: TiminatorAbsenceType) => {
        const response = await HttpClient.typeApi.add<TiminatorAbsenceType>('absences', type);
        return response.data
    }
)

export const updateAbsenceType = createAsyncThunk(
    'absences-types/update',
    async (type: TiminatorAbsenceType) => {
        const response = await HttpClient.typeApi.update<TiminatorAbsenceType>('absences', type);
        return response.data
    }
)

export const deleteAbsenceType = createAsyncThunk(
    'absences-types/delete',
    async (id: number) => {
        const response = await HttpClient.typeApi.delete<TiminatorAbsenceType>('absences', id);
        return response.data
    }
)

export const getShiftsTypes = createAsyncThunk(
    'shifts-types/get',
    async (page: number) => {
        const response = await HttpClient.typeApi.getTypes<TiminatorTypes>('shifts', page);
        return response.data
    }
)

export const addShiftType = createAsyncThunk(
    'shifts-types/create',
    async (type: TiminatorType) => {
        const response = await HttpClient.typeApi.add<TiminatorType>('shifts', type);
        return response.data
    }
)

export const updateShiftsType = createAsyncThunk(
    'shifts-types/update',
    async (type: TiminatorType) => {
        const response = await HttpClient.typeApi.update<TiminatorType>('shifts', type);
        return response.data
    }
)

export const deleteShiftType = createAsyncThunk(
    'shifts-types/delete',
    async (id: number) => {
        const response = await HttpClient.typeApi.delete<TiminatorType>('shifts', id);
        return response.data
    }
)

export const filterProjectType = createAsyncThunk(
    'project-types/filter',
    async (filter: string) => {
        const response = await HttpClient.typeApi.filterTypes<TiminatorType[]>('projects', filter);
        return response.data;
    }
)

const typeSplice = createSlice({
    name: 'types',
    initialState,
    reducers: {
        clearProjectTypes: (state) => {
            state.projects = initialState.projects;
        },
        clearAbsenceTypes: (state) => {
            state.absences = initialState.absences;
        },
        clearShiftTypes: (state) => {
            state.shifts = initialState.shifts;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectTypes.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                state.projects = action.payload;
            } else {
                state.projects.items = [...state.projects.items, ...action.payload.items];
                state.projects.offset = action.payload.offset;
            }
        })
        builder.addCase(getAbsencesTypes.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                state.absences = action.payload;
            } else {
                state.absences.items = [...state.absences.items, ...action.payload.items];
                state.absences.offset = action.payload.offset;
            }
        })
        builder.addCase(getShiftsTypes.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                state.shifts = action.payload;
            } else {
                state.shifts.items = [...state.shifts.items, ...action.payload.items];
                state.shifts.offset = action.payload.offset;
            }
        })
    }
})

export const { clearProjectTypes, clearAbsenceTypes, clearShiftTypes } = typeSplice.actions

export default typeSplice.reducer;
