import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {Client, Clients} from './types';

const initialState: Clients = {
    items: [],
    offset: 0,
    total: 0,
}

export const getClients = createAsyncThunk(
    'clients/get',
    async (page: number) => {
        const response = await HttpClient.clientApi.getClients<Clients>(page);
        return response.data
    }
)

export const addClient = createAsyncThunk(
    'clients/create',
    async (client: Client) => {
        const response = await HttpClient.clientApi.add<Client>(client);
        return response.data
    }
)

export const updateClient = createAsyncThunk(
    'clients/update',
    async (client: Client) => {
        const response = await HttpClient.clientApi.update<Client>(client);
        return response.data
    }
)

export const deleteClient = createAsyncThunk(
    'clients/delete',
    async (id: number) => {
        const response = await HttpClient.clientApi.delete<Client>(id);
        return response.data
    }
)

const typeSplice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        clearClients: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getClients.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                return action.payload
            } else {
                state.items = [...state.items, ...action.payload.items];
                state.offset = action.payload.offset;
            }
        })
    }
})

export const { clearClients } = typeSplice.actions

export default typeSplice.reducer;
