import React, {useEffect} from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import EditModal from '../../../../components/shared/modal/edit-modal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import {REmployees, Role} from '../../../../features/roles/types';
import {getRoles, updateRole} from '../../../../features/roles';
import FormAccordion, {TAccordionProps} from '../../../../components/shared/form-controls/form-accordion';
import {RolesFormHandbook} from '../../types';
import FormAutocomplete from '../../../../components/shared/form-controls/form-autocomplete-tag';
import {AutoCompleteItemValue} from '../../../../components/shared/form-controls/form-autocomplete-tag/types';
import {
    AccordionDetailImpl,
    AccordionGroup,
    AccordionGroupImpl
} from '../../../../components/shared/form-controls/form-accordion/types';
import {filterRoles} from '../../../../features/roles/filter';

interface Props extends RolesFormHandbook{
    role: Role;
}

interface EditForm {
    name: string;
    isActive: boolean;
    permissions?: number[];
    employees?: REmployees[];
    canEditEmployees?: boolean,
    canEditPermissions?: boolean,
}

export default function EditRoleModal(props: Props) {
    const {role} = props;
    const {permissions, employees} = props;
    const dispatch = useAppDispatch();
    const {open, onToggle, openModal} = useModal();
    const id = role.id;

    const initialValues: EditForm = {
        name: role.name,
        isActive: role.isActive,
        permissions: role.permissions,
        employees: role.employees,
        canEditEmployees: role.canEditEmployees,
        canEditPermissions: role.canEditPermissions,
    };

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }
        return await dispatch(filterRoles(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name) || name === initialValues.name.toLowerCase();
        });
    }

    useEffect(() => {
        openModal();
    }, [])

    const validationScheme: Yup.Schema<EditForm> = Yup.object().shape({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required('Required')
            .test(
                'role-name-backend-validation',
                'Role name is taken',
                async (name: string) => { return await validateName(name);}
            ),
        isActive: Yup.boolean().required(),
        permissions: Yup.array().optional(),
        employees: Yup.array().of(Yup.object().shape({
            id: Yup.number().required(),
            name: Yup.string().required(),
            isDefault: Yup.boolean().required(),
        })).optional(),
    })

    const save = async (values: EditForm) => {
        const updated: Role = {
            id,
            canEditEmployees: values.canEditEmployees ?? false,
            canEditPermissions: values.canEditPermissions ?? false,
            employees: values.employees ?? [],
            isActive: values.isActive,
            name: values.name,
            permissions: values.permissions ?? [],
        }

        return dispatch(updateRole(updated)).unwrap().then(() => {
            onToggle();
            dispatch(getRoles(0));
        })
    }

    const accordionGroups: AccordionGroup[] = permissions.map(group =>
        new AccordionGroupImpl(group.name,
            group.items.map(permission => {
                const result = new AccordionDetailImpl(permission.id, permission.name)
                result.selected = role.permissions.includes(permission.id);
                return result;
            })
        ))

    return <span>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={save}>
            <Form>
                <EditModal open={open} title='role' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Name' />
                    <fieldset>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                    {props.role.canEditEmployees && <FormAutocomplete
                        name='employees'
                        label='Employees' placeHolder='Start typing to add'
                        items={employees as AutoCompleteItemValue[]}
                        selectedItems={props.role.employees}
                    />}
                    {props.role.canEditPermissions && <label>Permissions</label>}
                    {props.role.canEditPermissions && <FormAccordion name='permissions' groups={accordionGroups} elementType={'Switch'}/>}
                </EditModal>
            </Form>
        </Formik>}
    </span>
}
