import {AxiosInstance, AxiosResponse} from 'axios';

export interface ISubscriptionApi {
    get<T>(): Promise<AxiosResponse<T>>
    connect<T>(): Promise<AxiosResponse<T>>
}

export default class SubscriptionApi implements ISubscriptionApi {
    private readonly _axiosInstance: AxiosInstance;
    private readonly controller: string = 'subscription';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async get<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}`);
    }

    async connect<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this.controller}/connect`);
    }
}