import React from 'react';
import {useAppDispatch} from '../../../../hooks/hooks';
import useModal from '../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {FormInput} from '../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../components/shared/form-controls/form-checkbox';
import CreateNewButton from '../../../../components/shared/create-new-button';
import CreateModal from '../../../../components/shared/modal/create-modal';
import { Client } from '../../../../features/clients/types';
import {addClient, getClients} from '../../../../features/clients';
import {filterClients} from '../../../../features/clients/filter';

interface CreateForm {
    name: string,
    isActive: boolean,
    contactPerson?: string,
    email?: string,
    note?: string,
    site?: string,
}

export default function CreateClientModal() {
    const dispatch = useAppDispatch();
    const {open, onToggle} = useModal();

    const initialValue: CreateForm = {
        name: '',
        isActive: true,
    };

    const validateName = async (name: string): Promise<boolean> => {
        name = name.toLowerCase();
        if (name === '') {
            return Promise.resolve(false);
        }
        return await dispatch(filterClients(name)).unwrap().then((values) => {
            return values.length < 1 || values.some(value => value.name.toLowerCase() !== name);
        });
    }

    const add = async (values: CreateForm) => {
        const updated: Client = {
            id: 0,
            name: values.name,
            isActive: values.isActive,
            contactPerson: values.contactPerson ?? '',
            email: values.email ?? '',
            note: values.note ?? '',
            site: values.site ?? '',
        }
        return dispatch(addClient(updated)).unwrap().then(() => {
            dispatch(getClients(0));
            onToggle();
        })
    }

    const validationScheme: Yup.Schema<CreateForm> = Yup.object().shape({
        name: Yup.string()
            .max(45, 'Must be 45 characters or less')
            .required('Required')
            .test(
                'client-name-backend-validation',
                'client name is taken',
                async (name: string) => { return await validateName(name)}
            ),
        isActive: Yup.boolean().required(),
        contactPerson: Yup.string().optional(),
        site: Yup.string().optional(),
        email: Yup.string().email().optional(),
        note: Yup.string().optional(),
    })

    return <>
        <CreateNewButton onClick={onToggle}/>
        {open && <Formik
            initialValues={initialValue}
            validationSchema={validationScheme}
            validateOnBlur={true}
            validateOnChange={false}
            onSubmit={add}>
            <Form>
                <CreateModal open={open} title='client' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Name' />
                    <FormInput name='contactPerson' label='Contact person' placeholder='Person name' />
                    <FormInput name='email' label='Email' placeholder='Email' />
                    <FormInput name='site' label='Site' placeholder='Write something' />
                    <FormInput name='note' label='Note' placeholder='Write something' />
                    <fieldset>
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </CreateModal>
            </Form>
        </Formik>}
    </>
}
