import React, {ReactElement} from 'react'
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {IRouteItem} from '../../../../routes/interfaces/IRouteItem';
import styles from './menu-item.module.scss';
import {getRootUrl} from '../../../../misc';
import {FormattedMessage} from 'react-intl';

interface MenuItemProps {
    route: IRouteItem,
    callback?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = (props): ReactElement => {
    const {route, callback} = props;

    return(
        <Link to={route.url} onClick={callback ? callback : void(0)} className={classNames({
            [styles.menuItem]: true,
            [styles.navItem]: true,
            [styles.active]: route.url === getRootUrl(location.pathname)
        })}>
            <span className={styles.svg}>{route.svg}</span>
            <span className={styles.text}><FormattedMessage id={route.name} /></span>
        </Link>
    )
}

export default MenuItem;
