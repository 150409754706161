import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {Part, Profile} from './types';

const initialState: Profile = {
    id: 0,
    name: '',
    parts: []
}

export const getProfile = createAsyncThunk(
    'profile/get',
    async () => {
        const response = await HttpClient.profileApi.getProfile();
        return response.data
    }
)

const profileSplice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.parts = action.payload.parts;
        })
    }
})

export default profileSplice.reducer;