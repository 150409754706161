import React, {useState, useEffect} from 'react';
import {Column} from '../../../../components/shared/table/types';
import {Box, Chip} from '@mui/material';
import Status from '../../../../components/shared/status';
import {SVGS} from '../../../../assets/images';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {useTableData} from '../../../../hooks/useTableData';
import {getShiftsTypes} from '../../../../features/types';
import TiminatorTable from '../../../../components/shared/table';
import CreateShiftTypesModal from './components/create-modal';
import EditShiftTypesModal from './components/edit-modal';
import DeleteShiftTypesModal from './components/delete-modal';
import {store} from '../../../../store/store';
import {selectIdOnEdit, selectIdOnDelete} from '../../../../features/modals';
import {TiminatorType} from '../../../../features/types/types';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {Project} from '../../../../features/projects/types';

const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        format: (rowData: TiminatorType) => {
            return <span>
                {rowData.name}{rowData.default && <Chip label="Default" />}
            </span>
        }
    },
    {
        id: 'status',
        label: 'Status',
        isStatus: true,
        align: 'left',
        format: (rowData: TiminatorType) => <Status color={rowData.active ? '#59CA59' : '#F26D6B'} value={rowData.active ? 'Active' : 'Suspended'} />
    },
];

const actions = [
    {
        name: 'Edit',
        icon: <SVGS.EditIcon />,
        callback: function(rowData: TiminatorType) {
            store.dispatch(selectIdOnEdit(rowData))
        },
    },
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon />,
        callback: function(rowData: TiminatorType) {
            store.dispatch(selectIdOnDelete(rowData))
        },
    }
]

const mapData = (item: TiminatorType): TiminatorType => {
    return item;
}

const sort = (a: TiminatorType, b: TiminatorType) => {
    if (a.active !== b.active) {
        return a.active ? -1 : 1;
    } else {
        return a.name > b.name ? 1 : -1;
    }
}

export default function ShiftTypes() {
    const dispatch = useAppDispatch();
    const shiftsTypes = useAppSelector(store => store.types.shifts)
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit)
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete)
    const tableSettings = useTableData(columns, actions, shiftsTypes.items, shiftsTypes.total, mapData, sort);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const fetchData = async (page: number, searchString?: string) => {
        dispatch(getShiftsTypes(page));
    }

    useEffect(() => {
        setIsEmpty(shiftsTypes.total === 0);
    }, [shiftsTypes.total])

    return <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        {!isEmpty
            ? <TiminatorTable tableSettings={tableSettings} fetchData={fetchData} offset={shiftsTypes.offset}/>
            : <IconTextLarge text='You have no project types' subtext='Create project type and use it for projects' icon={<SVGS.TypesIcon />} />
        }
        <CreateShiftTypesModal />
        {selectedEdit && <EditShiftTypesModal shiftType={selectedEdit as TiminatorType}/>}
        {selectedDelete && <DeleteShiftTypesModal shiftType={selectedDelete as TiminatorType}/>}
    </Box>
}
