import React, {ReactElement, useEffect, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../hooks/hooks'
import { login } from '../../features/auth/authSplice';
import styles from './login-page.module.scss';
import {useSearchParams} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const CODE_PARAM = 'code';

const LoginPage = (): ReactElement => {
    const auth = useAppSelector(store => store.auth);
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (!searchParams.has(CODE_PARAM)){
            return;
        }
        const code = searchParams.get(CODE_PARAM)!;
        dispatch(login(code));
    }, []);

    return <>
        <div className={styles.contentWrapper}>
            {
                auth && auth.status == 'failed' && searchParams.has(CODE_PARAM)
                && <p>
                    <FormattedMessage id={auth.error} />
                </p>
            }
            {
                auth && (auth.status === 'loading' || auth.status === 'idle') && searchParams.has(CODE_PARAM)
                && <div className={styles.loading}><FormattedMessage id={'logging_in'} />...</div>
            }
            {
                !searchParams.has(CODE_PARAM)
                && <p><FormattedMessage id={'authentication_is_not_done'} /></p>
            }
        </div>
    </>
}

export default LoginPage
