import { ConfigurationModalProps, IntegrationBannerProps } from '../../../../../../../features/integrations/types';
import { Integration } from '../../../../../../../features/settings/integrations/types';
import { IntegrationConfiguration } from '../functions';
import ZohoIntegrationBanner from './banner';
import ZohoConfigurationModal from './edit-modal';
import * as zohoSlice from '../../../../../../../features/integrations/zoho';
import { ThunkDispatch } from '@reduxjs/toolkit';

const useConnectZohoIntegration = async (dispatch: ThunkDispatch<any, any, any>, integration: Integration) => {
    return await dispatch(zohoSlice.enable({endpoint: integration.endpoints.enable})).unwrap().then((result) => {
        window.location.replace(result)
    })
}

export const zohoConfiguration: IntegrationConfiguration = {
    code: 'zoho',
    banner: (props: IntegrationBannerProps) => ZohoIntegrationBanner(props),
    modal: (props: ConfigurationModalProps) => ZohoConfigurationModal(props),
    connect: useConnectZohoIntegration,
    disconnect: (integration: Integration) => zohoSlice.disable({endpoint: integration.endpoints.disable})
}