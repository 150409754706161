import React, {useEffect, useState} from 'react';
import {Box, Stack} from '@mui/material';
import styles from './preferences.module.scss';
import {
    getEndDayTime,
    getStartDayTime,
    getTimeZone, updateStartDayTime, updateEndDayTime,
    updateTimeZone
} from '../../../../features/settings/preferences';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import {Dropdown} from '../../../../components/shared/dropdown';

const availableDayTimesObj = [
    {id: 1, time: '00:00'},
    {id: 2, time: '01:00'},
    {id: 3, time: '02:00'},
    {id: 4, time: '03:00'},
    {id: 5, time: '04:00'},
    {id: 6, time: '05:00'},
    {id: 7, time: '06:00'},
    {id: 8, time: '07:00'},
    {id: 9, time: '08:00'},
    {id: 10, time: '09:00'},
    {id: 11, time: '10:00'},
    {id: 12, time: '11:00'},
    {id: 13, time: '12:00'},
    {id: 14, time: '13:00'},
    {id: 15, time: '14:00'},
    {id: 16, time: '15:00'},
    {id: 17, time: '16:00'},
    {id: 18, time: '17:00'},
    {id: 19, time: '18:00'},
    {id: 20, time: '19:00'},
    {id: 21, time: '20:00'},
    {id: 22, time: '21:00'},
    {id: 23, time: '22:00'},
    {id: 24, time: '23:00'}
]

const timeZones= [
    {id: 14, name: '+14:00'},
    {id: 13, name: '+13:00'},
    {id: 12, name: '+12:00'},
    {id: 11, name: '+11:00'},
    {id: 10, name: '+10:00'},
    {id: 9, name: '+09:00'},
    {id: 8, name: '+08:00'},
    {id: 7, name: '+07:00'},
    {id: 6, name: '+06:00'},
    {id: 5, name: '+05:00'},
    {id: 4, name: '+04:00'},
    {id: 3, name: '+03:00'},
    {id: 2, name: '+02:00'},
    {id: 1, name: '+01:00'},
    {id: 0, name: '00:00'},
    {id: -1, name: '-01:00'},
    {id: -2, name: '-02:00'},
    {id: -3, name: '-03:00'},
    {id: -4, name: '-04:00'},
    {id: -5, name: '-05:00'},
    {id: -6, name: '-06:00'},
    {id: -7, name: '-07:00'},
    {id: -8, name: '-08:00'},
    {id: -9, name: '-09:00'},
    {id: -10, name: '-10:00'},
    {id: -11, name: '-11:00'},
    {id: -12, name: '-12:00'},
]

export default function Preferences() {
    const dispatch = useAppDispatch();
    const preferences = useAppSelector(store => store.preferences);
    const [timezoneLoading, setTimezoneLoading] = useState(true);
    const [startdaytimeLoading, setStartdaytimeLoading] = useState(true);
    const [enddaytimeLoading, setEnddaytimeLoading] = useState(true);

    useEffect(() => {
        setTimezoneLoading(true);
        setStartdaytimeLoading(true);
        setEnddaytimeLoading(true);
        dispatch(getTimeZone()).then(() => {
            setTimezoneLoading(false);
        });
        dispatch(getStartDayTime()).then(() => {
            setStartdaytimeLoading(false);
        });
        dispatch(getEndDayTime()).then(() => {
            setEnddaytimeLoading(false);
        });
    }, []);

    const getTwelveTimeIfNeeded = (time: string, enabled: boolean): string => {
        if (!enabled) {
            return time;
        }

        let hours = parseInt(time.split(':')[0]);
        const amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12;
        return `${hours}:00 ${amOrPm}`;
    }

    const dayTimesStringArray = availableDayTimesObj.map((value) => ({
        id: value.id,
        name: getTwelveTimeIfNeeded(value.time, false)
    }));

    const handleTimeZoneChange = (value: string) => {
        const timeZone = parseInt(value)
        if (timeZone === preferences.timezone){
            return;
        }
        dispatch(updateTimeZone(timeZone)).unwrap().then(() => {
            dispatch(getTimeZone());
        })
    }

    const handleStartDayTimeChange = (value: string) => {
        const dayTime = findDayTimeById(parseInt(value));
        const dayTimeWithTicks = `${dayTime}:00`;
        if (dayTimeWithTicks === preferences.startdaytime){
            return;
        }
        dispatch(updateStartDayTime(dayTimeWithTicks)).unwrap().then(() => {
            dispatch(getStartDayTime());
        })
    }

    const handleEndDayTimeChange = (value: string) => {
        const dayTime = findDayTimeById(parseInt(value));
        const dayTimeWithTicks = `${dayTime}:00`;
        if (dayTimeWithTicks === preferences.enddaytime){
            return;
        }
        dispatch(updateEndDayTime(dayTimeWithTicks)).unwrap().then(() => {
            dispatch(getEndDayTime());
        })
    }

    const findIdOfDayTime = (value: string): number => {
        return  availableDayTimesObj.find(dayTime => `${dayTime.time}:00` === value)!.id;
    }

    const findDayTimeById = (value: number): string => {
        return availableDayTimesObj.find(daytime => daytime.id === value)!.time;
    }

    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
        height: '100%',
        marginTop: '2rem',
        overflow: 'auto'
    }}>
        <Stack direction='column' className={styles.wrapper}>
            { !timezoneLoading && <Dropdown required={true} value={preferences.timezone} onBlur={handleTimeZoneChange}
                label='Time zone' name='timezone' items={timeZones}/> }
            { !startdaytimeLoading && <Dropdown required={true} value={findIdOfDayTime(preferences.startdaytime)} onBlur={handleStartDayTimeChange}
                label='Start day time' name='startdaytime' items={dayTimesStringArray}/> }
            { !enddaytimeLoading && <Dropdown required={true} value={findIdOfDayTime(preferences.enddaytime)} onBlur={handleEndDayTimeChange}
                label='End day time' name='enddaytime' items={dayTimesStringArray}/> }
        </Stack>
    </Box>
}