import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {REmployees, Role, Roles} from './types';

const initialState: Roles = {
    items: [],
    offset: 0,
    total: 0,
    canShowAndEditEmployees: false,
    canShowAndEditPermissions: false,
}

export const getRoles = createAsyncThunk(
    'roles/get',
    async (page: number) => {
        const response = await HttpClient.roleApi.getRoles<Roles>(page);
        return response.data
    }
)

export const addRole = createAsyncThunk(
    'roles/create',
    async (role: Role) => {
        const response = await HttpClient.roleApi.add<Role>(role);
        return response.data
    }
)

export const updateRole = createAsyncThunk(
    'roles/update',
    async (role: Role) => {
        const response = await HttpClient.roleApi.update<Role>(role);
        return response.data
    }
)

export const deleteRole = createAsyncThunk(
    'roles/delete',
    async (id: number) => {
        const response = await HttpClient.roleApi.delete<Role>(id);
        return response.data
    }
)

const typeSplice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        clearRoles: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRoles.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                return action.payload
            } else {
                state.items = [...state.items, ...action.payload.items];
                state.offset = action.payload.offset;
            }
        })
    }
})

export const { clearRoles } = typeSplice.actions

export default typeSplice.reducer;
