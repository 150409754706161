import { useField } from 'formik';
import React, {ChangeEventHandler} from 'react'
import styles from './form-input.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    name: string;
    placeholder?: string;
    limited?: boolean;
}

export const FormInput = (props: Props) => {
    const [field, meta] = useField(props);
    const limit = props.limited ? 45 : 240;
    return (
        <label htmlFor={props.id || props.name} className={styles.wrapper}>
            {props.label} {props.required==false ? (<span className={styles.optional}>(optional)</span>) : '' }
            <input className={props.className} type='text'
                style={{marginBottom: '0.3rem'}}
                aria-invalid={meta.error ? !!meta.error : undefined}
                {...field}
                {...props} />
            {props.limited && !meta.error && <div className={styles.letterLimit}>{field.value.length} of {limit}</div>}
            {meta.error ? (<div className={styles.error}>{meta.error}</div>) : null}
        </label>
    );
};
