import {AxiosInstance, AxiosResponse} from 'axios';

export interface IAuthAPI {
    login: (code: string) => Promise<AxiosResponse<string>>
}

export default class AuthAPI implements IAuthAPI{
    private readonly _axiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    async login(code: string): Promise<AxiosResponse<string>> {
        return await this._axiosInstance.get(`login?code=${code}`);
    }
}
