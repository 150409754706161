import {AxiosInstance, AxiosResponse} from 'axios';

const pageSize = 50;

export interface IProjectsApi {
    getAllProjects<T>(): Promise<AxiosResponse<T>>,
    getProjects<T>(projectTypeId: number, page: number): Promise<AxiosResponse<T>>,
    getProjectTabs<T>(): Promise<AxiosResponse<T>>,
    getProjectClients<T>(): Promise<AxiosResponse<T>>,
    filterProjects<T>(filter: string): Promise<AxiosResponse<T>>,
    add<T>(body: T): Promise<AxiosResponse<T>>,
    update<T extends {id: number}>(body: T): Promise<AxiosResponse<T>>,
    delete<T extends {id: number}>(id: number): Promise<AxiosResponse<T>>,
}

export default class ProjectApi implements IProjectsApi {
    private readonly _axiosInstance;
    private readonly controller = 'projects';
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    async getProjectTabs<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/types/filter`);
    }
    async getProjectClients<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/types/filter`);
    }
    async getAllProjects<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/filter`);
    }
    async getProjects<T>(projectTypeId: number, page: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/types/${projectTypeId}/projects?offset=${page * pageSize}&count=${pageSize}`);
    }
    async filterProjects<T>(filter: string): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/filter?q=${filter}`);
    }
    async add<T>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this.controller}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async update<T extends {id: number}>(body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this.controller}/${body.id}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async delete<T extends {id: number}>(id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.delete(`${this.controller}/${id}`);
    }
}
