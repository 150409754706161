import React, {useEffect, useState} from 'react';
import {Column} from '../../../../components/shared/table/types';
import { useTableData } from '../../../../hooks/useTableData';
import {useAppDispatch, useAppSelector} from '../../../../hooks/hooks';
import TiminatorTable from '../../../../components/shared/table';
import {getProjectTypes} from '../../../../features/types';
import { SVGS } from '../../../../assets/images';
import Status from '../../../../components/shared/status';
import {Box, Chip} from '@mui/material';
import CreateProjectTypesModal from './components/create-modal';
import EditProjectTypesModal from './components/edit-modal';
import { store } from '../../../../store/store';
import DeleteProjectTypesModal from './components/delete-modal';
import {selectIdOnDelete, selectIdOnEdit} from '../../../../features/modals';
import {TiminatorType} from '../../../../features/types/types';
import IconTextLarge from '../../../../components/shared/large-icon-text';
import {PopupMenuAction} from '../../../../components/shared/popup-menu/types';

const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        format: (rowData: TiminatorType) => {
            return <span>
                {rowData.name}{rowData.default && <Chip label="Default" />}
            </span>
        }
    },
    {
        id: 'status',
        label: 'Status',
        isStatus: true,
        align: 'left',
        format: (rowData: TiminatorType) => <Status color={rowData.active ? '#59CA59' : '#F26D6B'} value={rowData.active ? 'Active' : 'Suspended'} />
    },
];

const actions: PopupMenuAction[] = [
    {
        name: 'Edit',
        icon: <SVGS.EditIcon />,
        callback: function(rowData: TiminatorType) {
            store.dispatch(selectIdOnEdit(rowData))
        },
    },
    {
        name: 'Delete',
        icon: <SVGS.DeleteIcon />,
        callback: function(rowData: TiminatorType) {
            store.dispatch(selectIdOnDelete(rowData))
        },
    }
]

const mapData = (item: TiminatorType): TiminatorType => {
    return item;
}

const sort = (a: TiminatorType, b: TiminatorType) => {
    if (a.active !== b.active) {
        return a.active ? -1 : 1;
    } else {
        return a.name > b.name ? 1 : -1;
    }
}

export default function ProjectTypes() {
    const dispatch = useAppDispatch();
    const projectTypes = useAppSelector(store => store.types.projects)
    const selectedEdit = useAppSelector(store => store.modals.selectedEdit)
    const selectedDelete = useAppSelector(store => store.modals.selectedDelete)
    const tableSettings = useTableData(columns, actions, projectTypes.items, projectTypes.total, mapData, sort);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    const fetchData = async (page: number, searchString?: string) => {
        dispatch(getProjectTypes(page))
    }

    useEffect(() => {
        setIsEmpty(projectTypes.total === 0);
    }, [projectTypes.total])

    return <Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        {
            !isEmpty
                ? <TiminatorTable tableSettings={tableSettings} fetchData={fetchData} offset={projectTypes.offset}/>
                : <IconTextLarge text='You have no project types' subtext='Create project type and use it for projects' icon={<SVGS.TypesIcon />} />
        }
        <CreateProjectTypesModal />
        {selectedEdit && <EditProjectTypesModal projectType={selectedEdit as TiminatorType}/>}
        {selectedDelete && <DeleteProjectTypesModal projectType={selectedDelete as TiminatorType}/>}
    </Box>
}
