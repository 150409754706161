import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {Employee, Employees} from './types';

const initialState: Employees = {
    items: [],
    offset: 0,
    total: 0,
    canShowAndEditRoles: false,
    canShowAndEditProjects: false,
    canEditAdminLabel: false,
}

export const getEmployee = createAsyncThunk(
    'employee/get/single',
    async (id: number) => {
        const response = await HttpClient.employeeApi.getEmployee<Employee>(id);
        return response.data;
    }
)

export const getEmployees = createAsyncThunk(
    'employees/get',
    async (page: number) => {
        const response = await HttpClient.employeeApi.getEmployees<Employees>(page);
        return response.data
    }
)

export const addEmployee = createAsyncThunk(
    'employees/create',
    async (employee: Employee) => {
        const response = await HttpClient.employeeApi.add<Employee>(employee);
        return response.data
    }
)

export const updateEmployee = createAsyncThunk(
    'employees/update',
    async (employee: Employee) => {
        const response = await HttpClient.employeeApi.update<Employee>(employee);
        return response.data
    }
)

export const deleteEmployee = createAsyncThunk(
    'employees/delete',
    async (id: number) => {
        const response = await HttpClient.employeeApi.delete<Employee>(id);
        return response.data
    }
)

const typeSplice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        clearEmployees: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            if (action.payload.offset === 0) {
                return action.payload
            } else {
                state.items = [...state.items, ...action.payload.items];
                state.offset = action.payload.offset;
            }
        })
    }
})

export const { clearEmployees } = typeSplice.actions

export default typeSplice.reducer;
