import React from 'react'
import styles from './dialog-layout.module.scss'
import { Outlet } from 'react-router-dom'
import { SVGS } from '../../assets/images'

export default function DialogLayout() {

    return (<div className={styles.mainContainer}>
        <div className={styles.logo}>
            <SVGS.LogoJIcon style={{ width: '125px', height: '125px' }} />
        </div>
        <Outlet />
    </div>)
}