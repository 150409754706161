export interface AccordionGroup {
    name: string,
    subItems: AccordionDetail[]
}

export class AccordionGroupImpl implements AccordionGroup {
    constructor(name: string, subItems: AccordionDetail[]) {
        this.name = name;
        this.subItems = subItems;
    }
    name: string;
    subItems: AccordionDetail[];
}

export interface AccordionDetail{
    id: number,
    name: string,
    selected: boolean
}

export class AccordionDetailImpl implements AccordionDetail {
    constructor(id:number, name:string) {
        this.name = name;
        this.id = id;
        this.selected = false;
    }

    id: number;
    name: string;
    selected: boolean;
}