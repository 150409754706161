import React from 'react';
import CreateModal from '../../../../../../components/shared/modal/create-modal';
import {useAppDispatch} from '../../../../../../hooks/hooks';
import {addAbsenceType, getAbsencesTypes} from '../../../../../../features/types';
import {TiminatorAbsenceType} from '../../../../../../features/types/types';
import CreateNewButton from '../../../../../../components/shared/create-new-button';
import useModal from '../../../../../../hooks/useModal';
import {Form, Formik} from 'formik';
import {FormInput} from '../../../../../../components/shared/form-controls/form-input';
import {FormCheckbox} from '../../../../../../components/shared/form-controls/form-checkbox';
import * as Yup from 'yup';

interface CreateForm {
    name: string;
    isDefault: boolean;
    isActive: boolean;
    isVacation: boolean;
}

const validationScheme: Yup.Schema<CreateForm> = Yup.object({
    name: Yup.string()
        .max(45, 'Must be 45 characters or less')
        .required('Required'),
    isDefault: Yup.boolean().required(),
    isActive: Yup.boolean().required(),
    isVacation: Yup.boolean().required(),
})

export default function CreateAbsenceTypesModal() {
    const dispatch = useAppDispatch();
    const {open, onToggle} = useModal();

    const initialValues: CreateForm = {
        name: '',
        isDefault: false,
        isActive: true,
        isVacation: false,
    };

    const add = async (values: CreateForm) => {
        const type: TiminatorAbsenceType = {
            id: 0,
            name: values.name,
            active: values.isActive,
            default: values.isDefault,
            isVacation: values.isVacation,
        }
        return dispatch(addAbsenceType(type)).unwrap().then(() => {
            dispatch(getAbsencesTypes(0));
            onToggle();
        })
    }

    return <>
        <CreateNewButton onClick={onToggle}/>
        {open && <Formik
            initialValues={initialValues}
            validationSchema={validationScheme}
            onSubmit={add}>
            <Form>
                <CreateModal open={open} title='absence type' onClose={onToggle}>
                    <FormInput name='name' label='Name' placeholder='Absence type' />
                    <fieldset>
                        <FormCheckbox name='isVacation' label='Use for vacation'/>
                        <FormCheckbox name='isDefault' label='Default' />
                        <FormCheckbox name='isActive' label='Active'/>
                    </fieldset>
                </CreateModal>
            </Form>
        </Formik>}
    </>
}
