import {AxiosInstance, AxiosResponse} from 'axios';
import {Types} from './types';

const pageSize = 50;

export interface ITypeApi {
    getTypes<T>(name: Types, page: number): Promise<AxiosResponse<T>>,
    filterTypes<T>(name: Types, filter: string): Promise<AxiosResponse<T>>,
    add<T>(name: Types, body: T): Promise<AxiosResponse<T>>,
    update<T extends {id: number}>(name: Types, body: T): Promise<AxiosResponse<T>>,
    delete<T extends {id: number}>(name: Types, id: number): Promise<AxiosResponse<T>>,
}

export default class TypeApi implements ITypeApi {
    private readonly _axiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    async getTypes<T>(name: Types, page: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${name}/types?offset=${page * pageSize}&count=${pageSize}`);
    }

    async filterTypes<T>(name: Types, filter: string): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${name}/types/filter?q=${filter}`);
    }

    async add<T>(name: Types, body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${name}/types`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async update<T extends {id: number}>(name: Types, body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${name}/types/${body.id}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async delete<T extends {id: number}>(name: Types, id: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.delete(`${name}/types/${id}`);
    }
}
