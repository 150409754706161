import React from 'react'
import './App.scss'
import {CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter} from 'react-router-dom'
import AppRouter from './routes/AppRouter'
import theme from './themes/main/theme'
import useSnacks from './hooks/useSnacks'
import {IntlProvider} from 'react-intl';
import {messages} from './i18n/messages';
import {LOCALES} from './i18n/locales';

function App() {
    const locale = LOCALES.ENGLISH;
    useSnacks()
    return (
        <div className="App">
            <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.ENGLISH}>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <AppRouter />
                    </BrowserRouter>
                </ThemeProvider>
            </IntlProvider>
        </div>
    )
}

export default App
