import React, { ReactNode } from 'react'
import { ConfigurationModalProps, IntegrationBannerProps } from '../../../../../../features/integrations/types'
import { Integration } from '../../../../../../features/settings/integrations/types'
import { zohoConfiguration } from './zoho/configuration'
import { AsyncThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom'

export interface IntegrationConfiguration {
    code: string,
    banner: (props: IntegrationBannerProps) => ReactNode,
    modal: (props: ConfigurationModalProps) => ReactNode,
    connect: (dispatch: ThunkDispatch<any, any, any>, integration: Integration) => Promise<void>,
    disconnect: (integration: Integration) => AsyncThunkAction<any,any, any>
}

const integrationPages: Array<IntegrationConfiguration> = [
    zohoConfiguration
]

export const disconnectIntegration = (integration: Integration) => integrationPages.find(x => x.code == integration.id)!.disconnect(integration)

export const connectIntegration = (dispatch: ThunkDispatch<any, any, any>, integration: Integration) => integrationPages.find(x => x.code == integration.id)!.connect(dispatch, integration)