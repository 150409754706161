import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ZohoConfiguration, ZohoIntegration } from './types';
import HttpClient from '../../../api/api';
import { act } from 'react-dom/test-utils';

const configuration: ZohoConfiguration = {
    organizationId: '',
    synchronizationType: 0,
    employeesForSynchronization: [],
    employeesForNotification: [],
    projectsForSynchronization: []
}

const initialState: ZohoIntegration = {
    enabled: false,
    configuration: configuration,
    availableEmployeesForNotifications: [],
    availableEmployeesForSynchronization: [],
    availableOrganizations: [],
    availableProjects: [],
}

export const enable = createAsyncThunk(
    'zoho/enable',
    async (args: { endpoint: string }) => {
        const response = await HttpClient.integrationsApi.enable<string>(args.endpoint);
        return response.data;
    }
)

export const get = createAsyncThunk(
    'zoho/get',
    async (args: { endpoint: string }) => {
        const response = await HttpClient.integrationsApi.get<ZohoIntegration>(args.endpoint);
        return response.data;
    }
)

export const configure = createAsyncThunk(
    'zoho/configure',
    async (args: { endpoint: string, configuration: ZohoConfiguration }) => {
        const response = await HttpClient.integrationsApi.configure(args.endpoint, args.configuration)
        return response.data;
    }
)

export const disable = createAsyncThunk(
    'zoho/disable',
    async (args: { endpoint: string }) => {
        const response = await HttpClient.integrationsApi.disable(args.endpoint);
        return response.data;
    }
)

const typeSlice = createSlice(
    {
        name: 'zoho',
        initialState,
        reducers: {
            clearConfiguration: (state) => {
                state.configuration = initialState.configuration
            },
            clearAvailableEmployeesForNotifications: (state) => {
                state.availableEmployeesForNotifications = initialState.availableEmployeesForNotifications
            },
            clearAvailableEmployeesForSynchronization: (state) => {
                state.availableEmployeesForSynchronization = initialState.availableEmployeesForSynchronization
            },
            clearAvailableProjectsForSynchronization: (state) => {
                state.availableProjects = initialState.availableProjects
            },
            clearAvailableOrganizations: (state) => {
                state.availableOrganizations = initialState.availableOrganizations
            }
        },
        extraReducers: (builder) => {
            builder.addCase(get.fulfilled, (state, action) => {
                state.enabled = action.payload.enabled
                state.configuration = action.payload.configuration
                state.availableProjects = action.payload.availableProjects
                state.availableEmployeesForNotifications = action.payload.availableEmployeesForNotifications
                state.availableEmployeesForSynchronization = action.payload.availableEmployeesForSynchronization
                state.availableOrganizations = action.payload.availableOrganizations
            })
        }
    }
)

export const { clearAvailableEmployeesForNotifications, clearAvailableEmployeesForSynchronization, clearAvailableProjectsForSynchronization, clearAvailableOrganizations, clearConfiguration } = typeSlice.actions;

export default typeSlice.reducer;

