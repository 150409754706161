import {AxiosInstance, AxiosResponse} from 'axios';
import { WorkCalendar } from './types';

export interface IWorkCalendarApi {
    getRegions<T>(): Promise<AxiosResponse<T>>
    getAll<T>(): Promise<AxiosResponse<T>>,
    get<T>(workCalendarId: number, year: number): Promise<AxiosResponse<T>>,
    getMonth<T>(workCalendarId: number, year: number, month: number): Promise<AxiosResponse<T>>,
    getDay<T>(workCalendarId: number, year: number, month: number, day: number): Promise<AxiosResponse<T>>,
    updateMonth<T extends { month: number }>(workCalendarId: number, year: number, body: T): Promise<AxiosResponse<T>>,
    updateDay<T extends { day: number }>(workCalendarId: number, year: number, month: number, body: T): Promise<AxiosResponse<T>>
    createWorkCalendar<T extends WorkCalendar>(workCalendar: WorkCalendar): Promise<AxiosResponse<T>>
    updateWorkCalendar<T extends WorkCalendar>(workCelendar: WorkCalendar): Promise<AxiosResponse<T>>
    deleteWorkCalendar<T extends WorkCalendar>(id: number): Promise<AxiosResponse<T>>
    generateWorkCalendar<T>(id: number, year: number): Promise<AxiosResponse>
}
export default class WorkCalendarApi implements IWorkCalendarApi {
    private readonly _axiosInstance: AxiosInstance;
    private readonly controller: string = 'workcalendar';

    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getRegions<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/regions`)
    }

    async getAll<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}`);
    }

    async get<T>(workCalendarId: number, year: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/${workCalendarId}/${year}`);
    }

    async getMonth<T>(workCalendarId: number, year: number, month: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/${workCalendarId}/${year}/${month}`);
    }

    async getDay<T>(workCalendarId: number, year: number, month: number, day: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this.controller}/${workCalendarId}/${year}/${month}/${day}`);
    }

    async updateMonth<T extends { month: number }>(workCalendarId: number, year: number, body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this.controller}/${workCalendarId}/${year}/${body.month}`, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
    }

    async updateDay<T extends { day: number }>(workCalendarId: number, year: number, month: number, body: T): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.put<T>(`${this.controller}/${workCalendarId}/${year}/${month}/${body.day}`, JSON.stringify(body), { headers: { 'Content-Type': 'application/json' } });
    }

    async createWorkCalendar<T extends WorkCalendar>(workCalendar: WorkCalendar): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.post<T>(`${this.controller}/create`, JSON.stringify(workCalendar), { headers: { 'Content-Type': 'application/json' } })
    }

    async updateWorkCalendar<T extends WorkCalendar>(workCelendar: WorkCalendar): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.put<T>(`${this.controller}/edit`, JSON.stringify(workCelendar), { headers: { 'Content-Type': 'application/json' } })
    }

    async deleteWorkCalendar<T extends WorkCalendar>(id: number): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.delete<T>(`${this.controller}/${id}`)
    }

    async generateWorkCalendar<T>(id: number, year: number): Promise<AxiosResponse<any, any>> {
        return await this._axiosInstance.post<T>(`${this.controller}/${id}/${year}`)
    }
}