import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../../api/api';
import {PIdNameModel} from '../../projects/types';


const initialState: PIdNameModel[] = []

export const getAllClients = createAsyncThunk(
    'client/all',
    async () => {
        const response = await HttpClient.clientApi.getAllClients<PIdNameModel[]>();
        return response.data
    }
)

export const filterClients = createAsyncThunk(
    'client/filter',
    async (filter: string) => {
        const response = await HttpClient.clientApi.filterClients<PIdNameModel[]>(filter);
        return response.data;
    }
)

const typeSplice = createSlice({
    name: 'pClient',
    initialState,
    reducers: {
        clear: (state) => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllClients.fulfilled, (state, action) => {
            return [...action.payload];
        })
    }
})

export const { clear } = typeSplice.actions

export default typeSplice.reducer;
