import React from 'react'
import PageWrapper from '../../components/shared/page-wrapper';
import {Box, Button} from '@mui/material';
import {useAppSelector} from '../../hooks/hooks';
import {SVGS} from '../../assets/images';
import styles from './profile.module.scss'
import {useNavigate} from 'react-router-dom';
import ProfileSectionBanner from './components/profile-section-banner';
import {store} from '../../store/store';
import {logout} from '../../features/auth/authSplice';
import {FormattedMessage, useIntl} from 'react-intl';

export default function ProfilePage() {
    const intl = useIntl();
    const logoutMe = () => store.dispatch(logout())
    const employee = useAppSelector(store => store.employee);
    const handleLogOut = () => {
        logoutMe()
    }

    return  <PageWrapper>
        <Box justifyContent='space-between' display='flex' alignItems='start'>
            <h3><FormattedMessage id={'profile'} /></h3>
            <Button variant='createNew' onClick={() => handleLogOut()} className={styles.logout}>
                <SVGS.LogOutIcon /><span style={{marginLeft: 10}}>{intl.formatMessage({id: 'log_out'})}</span>
            </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={styles.profileName}>
            <h4>{ `${employee.firstName} ${employee.lastName}` }</h4>
        </Box>
        <Box sx={{ marginTop: '2rem'}}>
            {
                employee.email && <ProfileSectionBanner name={intl.formatMessage({id: 'email'})} content={employee.email} />
            }
        </Box>
    </PageWrapper>
};
