import {AnyAction, createSlice} from '@reduxjs/toolkit';
import {StateStatus} from './types';

const initialState = {
    status: StateStatus.FULFILLED,
};

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action: AnyAction) => action.type.includes('/pending'),
                (state) => {
                    state.status = StateStatus.PENDING;
                }
            )
            .addMatcher(
                (action: AnyAction) => action.type.includes('/fulfilled'),
                (state) => {
                    state.status = StateStatus.FULFILLED;
                }
            )
            .addMatcher(
                (action: AnyAction) => action.type.includes('/rejected'),
                (state) => {
                    state.status = StateStatus.FULFILLED;
                }
            );
    },
});

export default loaderSlice.reducer;
