import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks/hooks'
import * as zoho from '../../../../../../../../features/integrations/zoho'
import IntegrationBanner, { State } from '../../../integration-banner'
import { FormattedMessage, useIntl } from 'react-intl'
import { IntegrationBannerProps } from '../../../../../../../../features/integrations/types'
import { Skeleton } from '@mui/material'

const ZohoIntegrationBanner = (props: IntegrationBannerProps) => {
    const intl = useIntl();
    const integrationData = useAppSelector(store => store.integrationZoho);
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false)
    const { endpoints, enabled } = props.integration

    const handleConnectClick = () => {
        props.onConnectClick(props.integration)
    }

    const handleConfigureClick = () => {
        props.onConfigureClick(integrationData)
    }

    const handleDisconnectClick = () => {
        props.onDisconnectClick(props.integration)
    }

    const getIntegrationState = (): State => {
        return props.integration.enabled && integrationData && integrationData.enabled ? State.Active : props.integration.enabled && integrationData && !integrationData.enabled ? State.Awaiting : State.Disabled
    }

    const getBanner = (state: State) => {
        return (<IntegrationBanner name={'Zoho'}
            enabled={state}
            onConnectClick={handleConnectClick}
            onConfigureClick={handleConfigureClick}
            onDisconnectClick={handleDisconnectClick}>
            <span>
                <FormattedMessage id='zoho_integration_desc' />:&nbsp;
                <a href={intl.formatMessage({ id: 'zoho_integration_link' })}>
                    {intl.formatMessage({ id: 'zoho_integration_link' })}
                </a>
            </span>
        </IntegrationBanner>)
    }

    useEffect(() => {
        setLoading(true)
        dispatch(zoho.get({ endpoint: endpoints.get })).then(() => {
            setLoading(false)
        })
    }, [])

    return (<>
        {
            !loading && integrationData ? getBanner(getIntegrationState()) : <Skeleton width={1619} height={200} />
        }
    </>)
}

export default ZohoIntegrationBanner